import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Technologies from '../../components/page-parts/technologies';
import FormSection from '../../components/page-parts/form-section';
import MainScreenPortfolio from "../../components/portfolio-page/main-banner";
import PortfolioPageProjects from "../../components/portfolio-page/projects-section";

const Portfolio = () => {

  const pageTitle = 'Наши работы. Проекты, разработанные нашей студией'
  const pageDescription = 'Создаем сайты любой сложности. Рисуем узнаваемый дизайн. Настраиваем качественную контекстную рекламу. Соблюдаем сроки. Некоторые работы из нашего портфолио. Закажите разработку сайта, дизайна и настройку рекламы для вашего бизнеса в нашей студии Ascent.'

  // const keywords = ''

  return (
    <Layout fixedHeader={true}>
      <Seo title={pageTitle} description={pageDescription} />
      <MainScreenPortfolio />
      <PortfolioPageProjects />
      <Technologies />
      <FormSection />
    </Layout>
  )
}

export default Portfolio
