import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import ArrowIcon from '../../svg/arrowRightGrdnt.svg'
import { BgImage } from 'gbimage-bridge';
import { getImage } from 'gatsby-plugin-image';

const useStyles = makeStyles((theme) => ({

  bg: {
    position: 'relative',
    zIndex: 10,
  },

  wrapper: {
    height: '65vh',
    color: '#fff',
    alignItems: 'flex-end',
    position: 'relative',
    overflow: 'hidden',

    '&:before': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: [theme.palette.gradientDark.main60],
    },
  },

  contentBox: {
    textAlign: 'center',
    position: 'relative',
    zIndex: 3,
  },

  title: {

    textTransform: 'uppercase',

    '& span': {
      background: [theme.palette.gradientBlue.main],
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    },
  },

  subtitle: {
    fontSize: '1.2rem',
    lineHeight: 1.2,
    fontWeight: 400,
  },

  // '@media (min-width: 600px)': {
  //   wrapper: {
  //     minHeight: '820px',
  //   },
  // },

  // '@media (min-width: 768px)': {
  //   wrapper: {
  //     minHeight: '1024px',
  //   },
  // },

  [theme.breakpoints.up('sm')]: {

    wrapper: {
      alignItems: 'center',
      zIndex: 2,
      minHeight: '50vh',
      // height: '900px',


      // '&:before': {
      //   content: 'none',
      // },
    },

    subtitle: {
      fontSize: '1.5rem',
    },

    contentBox: {
      textAlign: 'left',
    }
  },

  [theme.breakpoints.up('md')]: {
    wrapper: {
      height: '900px',


      '&:before': {
        content: 'none',
      },
    },
  },

}))

const MainScreenPortfolio = () => {

  const { desktopImage } = useStaticQuery(
    graphql`
      query {
        # mobileImage: file(relativePath: { eq: "portfolio-page/bg.png" }) {
        #   childImageSharp {
        #     gatsbyImageData(
        #       width: 400
        #       placeholder: BLURRED
        #       formats: [AUTO, WEBP, AVIF]
        #     )
        #   }
        # }
        desktopImage: file(relativePath: { eq: "portfolio-page/bg.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )
  // const sources = [
  //   ...getImage(mobileImage),
  //   {
  //     ...getImage(desktopImage),
  //     media: `(min-width: 491px)`,
  //   },
  // ]

  const pluginImage = getImage(desktopImage)

  const classes = useStyles()


  return (
    <BgImage image={pluginImage} Tag="section" className={classes.bg} >
      <Box className={classes.wrapper} display="flex" py={3}>
          <Container maxWidth="xl">
            <Box className={classes.contentBox}>
              <Grid
                container
              >
                <Grid item sm={6}>
                  <Box className={classes.titleBox} mb={2}>
                    <Typography variant="h1" className={classes.title}>наше<br/><span>портфолио</span></Typography>
                  </Box>
                  <Box 
                    className={classes.subtitleBox}
                    mb={{xs: 5, md: 8}}
                  >
                    <h2 className={classes.subtitle}>Посмотрите некоторые выполненные кейсы нашей студией, которыми мы гордимся</h2>
                  </Box>
                  <Box className={classes.btnBox}>
                    <Button href="#services-list" variant="outlined" color="inherit" endIcon={<ArrowIcon />} size="large">Оставить заявку</Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
      </Box>
    </BgImage>
  )
}

export default MainScreenPortfolio
