import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, Button, Container, Typography } from '@material-ui/core'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Logo from '../../svg/logo-white.svg'
import ArrowIcon from '../../svg/arrowRight.svg'
import { useStaticQuery, graphql, Link } from 'gatsby';

const useStyles = makeStyles((theme) => ({

  wrapper: {
    padding: '50px 0',
    position: 'relative',
    zIndex: 4,
  },

  titleBox: {
    marginBottom: '40px',
  },

  gridItem: {
    position: 'relative',
    overflow: 'hidden',
    zIndex: 1,
    cursor: 'pointer',
    marginBottom: '30px',

    '&:hover .cardOverlay': {
      transform: 'translateY(0)',
    },
  },
  
  img: {
    display: 'block',
  },

  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 2,
    color: '#fff',
    top: 0,
    left: 0,
    padding: '50px 50px 50px 40px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transform: 'translateY(-105%)',
    transition: 'all .3s ease-out',

    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: -1,
      background: [theme.palette.gradientBlue.main],
      top: 0,
      left: 0,
      opacity: .85,
    },
  },

  description: {
    fontSize: '18px',
    fontWeight: 700,
    textTransform: 'uppercase',
  },

  lastBtnBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  linkBtn: {
    color: 'white',
    textDecoration: 'none',

    '& button': {
      '& svg': {
        fill: 'white',
        transition: 'fill .3s',
      },

      '&:hover': {
        borderColor: '#fff !important',
        // backgroundColor: [theme.palette.text.primary],
        backgroundColor: 'white',
        color: [theme.palette.text.primary],

        '& svg': {
          fill: [theme.palette.text.primary],
        },
      }
    },
  },

  [theme.breakpoints.up('sm')]: {

    overlay: {
      padding: '20px',
    },

    gridBox: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridGap: '20px',
    },

    description: {
      fontSize: '16px',
    },
  },

  [theme.breakpoints.up('md')]: {

    titleBox: {
      marginBottom: '70px',

      '& .subtitle': {
        width: '70%',
      },
    },

    gridBox: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridGap: '30px',
    },

    overlay: {
      padding: '40px',
    },

    description: {
      fontSize: '24px',
    },
  },
}))

const PortfolioPageProjects = () => {

  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query ProjectsQueryPortfolioPage {
      projects: allContentfulProject(sort: {fields: orderNumber}) {
        edges {
          node {
            id
            slug
            name
            smallImage {
              localFile {
                childImageSharp {
                  gatsbyImageData (
                    quality: 80
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Box
      component="section"
      className={classes.wrapper}
    >
      <Container maxWidth="xl">
        <Box className={classes.titleBox}>
          <Typography variant="h2" className={`${classes.title} title`}>портфолио</Typography>
          <Typography variant="body1" className="subtitle">— Каждый проект имеет уникальный дизайн и свои технологии разработки. Мы не используем «шаблонные» решения и к каждой работе подходим индивидуально. </Typography>
        </Box>
        <Box className={classes.gridBox}>
          {
            data.projects.edges.map(({node}) => {

              const image = getImage(node.smallImage.localFile.childImageSharp.gatsbyImageData)

              return (
                <Box className={classes.gridItem} key={node.id}>
                  <Box className={classes.imgBox}>
                    <GatsbyImage
                      image={image}
                      alt={node.name}
                    />
                  </Box>
                  <Box className={`${classes.overlay} cardOverlay`}>
                    <div className={classes.logo}>
                      <Logo />
                    </div>
                    <div className={classes.descrBox}>
                      <p className={classes.description}>{node.name}</p>
                    </div>
                    <div className={classes.btnBox}>
                      <Link to={`/portfolio/${node.slug}`} className={classes.linkBtn} >
                        <Button variant="outlined" color="inherit" endIcon={<ArrowIcon />} size="medium">подробнее</Button>
                      </Link>
                    </div>
                  </Box>
                </Box>
              )
            })
          }
        </Box>
      </Container>
    </Box>
  )
}

export default PortfolioPageProjects
